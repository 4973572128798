const swiper = new Swiper('#home-slider', {
    lazy: true,
    loop: true,
    direction: 'vertical',
    centeredSlides: false,
    slidesPerView: 'auto',
    autoplay: {
        delay: 2500,
        disableOnInteraction: true,
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    breakpoints: {
        720: {
            direction: 'horizontal',
            centeredSlides: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            grabCursor: true,
        },
        // '@0.00': {
        //     slidesPerView: 'auto',
        //     centeredSlides: false,
        //     direction: 'vertical',
        // },
        // '@1.77': {
        //     slidesPerView: 'auto',
        //     centeredSlides: true,
        //     direction: 'horizontal',
        // },
        // '@0.75': {
        //     // slidesPerView: 1,
        //     slidesPerView: 'auto',
        //     centeredSlides: true,
        //     direction: 'horizontal',
        // },
        // '@1.50': {
        //     // slidesPerView: 1.5,
        //     slidesPerView: 'auto',
        //     centeredSlides: true,
        //     direction: 'horizontal',
        // },
    },
});
